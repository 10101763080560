import {
  Button,
  Spinner,
  Textarea,
  TextareaOnChangeData,
  makeStyles,
  shorthands,
  tokens
} from "@fluentui/react-components";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { MessageContext, MessageInfo } from "../../MessageContext";
import { sendRequest } from "../../apis/OffersApi";
import { PageLayout } from "../../components";
import { AdminGrantingDialog, ConfiguringAccessDialog } from "../../components/dialogs";
import config from "../../config";
import { Auth } from "../../utils/auth";

const useStyles = makeStyles({
  body: {
    backgroundColor: tokens.colorNeutralBackground1,
    display: "flex",
    flexDirection: "column",
    maxWidth: "720px",
    ...shorthands.padding(0, "1em")
  },
  header: {
    textAlign: "center"
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "right",
    ...shorthands.margin("1em", 0)
  },
  description: {
    ...shorthands.margin(0, 0, "1em")
  },
  cancel: {
    marginRight: "2em",
    color: "#4F52B2"
  }
});

export const LicenseRequestPage = () => {
  const navigate = useNavigate();
  
  const [grantingDialogOpen, setGrantingDialogOpen] = useState(false);
  const [configuringDialogOpen, setConfiguringDialogOpen] = useState(false);
  const [message, setMessage] = useState<MessageInfo>(null);
  const [input, setInput] = useState("");
  const [validated, setValidated] = useState(true);
  const [newOfferId] = useState(uuidv4());
  const [isLoading, setLoading] = useState<boolean>(false);
  
  const onChange = (_: any, data: TextareaOnChangeData) => {
    setInput(data.value);
    setValidated(true);
  };

  const onCancelClick = () => navigate("/tab");

  const onSubmitClick = () => {
    if (input.trim().length === 0) {
      setValidated(false);
      setMessage({
        intent: "error",
        title: "Validation error",
        description: "Fill out the form"
      });
      return;
    } 

    setLoading(true);
    sendRequest(newOfferId, input)
      .then(() => {
        setLoading(false);
        MessageContext.message = {
          intent: "success",
          description: "Your request has been submitted for review"
        }
        navigate("/tab");
      })
      .catch((e: Error) => {
        setLoading(false);
        setMessage({
          intent: "error",
          title: "Error",
          description: e.message
        });
      });
  };

  useEffect(() => {
    if (grantingDialogOpen) return;
    
    checkPermissions()
      .then(result => setGrantingDialogOpen(!result.data))
      .catch((e: any) => {
        if (e.code === "InternalError") {
          navigate("../tab");
        } else {
          setMessage({
            intent: "error",
            title: "Error",
            description: e.message
          });
        }
      });
  }, [grantingDialogOpen, navigate]);

  const checkPermissions = async () => {
    const credential = Auth.getCredential();
    const user = await credential.getUserInfo();
    return await axios.get<boolean>(`${config.infoboardBackendUrl}/tenants/permissions?tenantId=${user.tenantId}`);
  };

  const onGrantingClick = async () => {
    const credential = Auth.getCredential();
    const user = await credential.getUserInfo();
    const redirectUri = `${config.infoboardBackendUrl}/tenants/registration`;
    const adminConsentUrl = `https://login.microsoftonline.com/${user.tenantId}/adminconsent?client_id=${config.backendClientId}&redirect_uri=${redirectUri}`;
    const size = 600;
    const top = (window.screen.height - size) / 2;
    const left = (window.screen.width - size) / 2;
    const newWindow = window.open(adminConsentUrl, "auth", `width=${size},height=${size},top=${top},left=${left}`);
    if (newWindow === null) {
      navigate("../tab");
      return;
    }
    
    const timer = setInterval(function () {
      if (newWindow.closed) {
        clearInterval(timer);
        setGrantingDialogOpen(false);
      }
      try {
        if (newWindow.location?.origin === window.location.origin) {
          clearInterval(timer);
          newWindow.close();
          setGrantingDialogOpen(false);
          setConfiguringDialogOpen(true);
        }
      } catch (e) {
        console.debug("Cannot read popup window location due to cross-origin policy");
      }
    }, 1000);
  }
  
  const onAdminGrantingCancelClick = () => {
    setGrantingDialogOpen(false);
    navigate("/tab");
  }

  const onConfiguringAccessCancelClick = () => {
    setConfiguringDialogOpen(false);
  }

  const onContinueClick = () => {
    window.open('https://admin.teams.microsoft.com/policies/app-setup')
  }

  const onDismiss = () => setMessage(null);

  const styles = useStyles();

  return (
    <PageLayout message={message} onDismiss={onDismiss}>
      <div className={styles.body}>
        <h3 className={styles.header}>License Assistant</h3>
        <p className={styles.description}>Please describe in simple terms any issues you have encountered related to software licensing, or specify the product/feature you require. Our engineers will determine the most appropriate solution and will request approval from your company’s administrators. We will keep you updated on the decision reached.</p>
        <Textarea
          style={validated ? {} : {borderColor: "red"}}
          appearance="outline"
          placeholder="Enter your request here, for example:
I'm having trouble organizing and analyzing large data sets. Can you recommend any solutions or tools to simplify this process?"
          resize="vertical"
          onChange={onChange}
          rows={8}
        />
        <div className={styles.buttons}>
          <Button className={styles.cancel} onClick={onCancelClick} size="large">
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            icon={isLoading ? <Spinner size="tiny" /> : null}
            onClick={onSubmitClick}
            size="large"
            appearance="primary">
              Submit
          </Button>
        </div>
      </div>
      
      <AdminGrantingDialog
        isOpen={grantingDialogOpen}
        onGrantingClick={onGrantingClick}
        onCancelClick={onAdminGrantingCancelClick}
      />
      <ConfiguringAccessDialog
        isOpen={configuringDialogOpen}
        onCancelClick={onConfiguringAccessCancelClick}
        onContinueClick={onContinueClick}
      />
    </PageLayout>
  );
}
