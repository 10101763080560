import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Spinner,
  Text,
  makeStyles,
  shorthands
} from "@fluentui/react-components";
import { useNavigate, useParams } from "react-router-dom";
import { MessageContext, MessageInfo } from "../../MessageContext";
import { getTenantUsers } from "../../apis/GraphApi";
import { approveOffer, declineOffer, getOffer, redirectOffer } from "../../apis/OffersApi";
import { PageLayout } from "../../components";
import { ApproveButton, DeclineButton, RedirectButton } from "../../components/buttons";
import { CommentDialog, RedirectDialog } from "../../components/dialogs";
import { SelectOption } from "../../components/inputs";
import logo from "../../images/itp_logo.png";
import { OfferDto, OfferStatus } from "../../models";

const useStyles = makeStyles({
  card: {
    width: "1000px",
    maxWidth: "100%",
    borderRadius: "10px",
    ...shorthands.padding(0)
  },
  decisionMadeCard: {
    width: "350px",
    alignItems: "center",
    borderRadius: "7px",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#0099DA",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    gap: "1em",
    ...shorthands.padding(0, "36px")
  },
  title: {
    fontSize: "24",
    color: "white",
    fontFamily: "Montserrat, sans-serif",
    textShadow: "0px 4px 4px #00000025"
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    gap: "8px",
    ...shorthands.margin("1em", "2em"),
    "@media (max-width: 464px)": {
      alignItems: "center",
      flexDirection: "column",
    }
  },
  decisionMadeCardButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    gap: "10px",
    width: "300px",
  },
  button: {
    height: "32px",
    "@media (max-width: 464px)": {
      width: "200px"
    }
  }
});

export const ConsiderOfferPage = () => {
  const offerId = useParams().id;
  const navigate = useNavigate();

  const [message, setMessage] = useState<MessageInfo>(null);
  const [offer, setOffer] = useState<OfferDto>(null);
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [approveDialogOpen, setApproveDialogOpen] = useState(false);
  const [declineDialogOpen, setDeclineDialogOpen] = useState(false);
  const [redirectDialogOpen, setRedirectDialogOpen] = useState(false);

  useEffect(() => {
    getOffer(offerId)
      .then(res => setOffer(res.data))
      .catch(err => alert(err));
  }, [offerId]);

  useEffect(() => {
    if (options.length > 0 || !redirectDialogOpen) return;
    
    getTenantUsers()
      .then(result => {
        const items = result
          .filter(u => !offer.decisionMakers.includes(u.userPrincipalName) && offer.initiatorEmail !== u.userPrincipalName)
          .map(option => ({
            name: `${option.displayName} (${option.userPrincipalName})`,
            value: option.userPrincipalName
          }));
        setOptions(items);
      })
      .catch(err => alert(err));
  }, [offer, options, redirectDialogOpen]);

  const onDismiss = () => setMessage(null);

  const styles = useStyles();

  const onRedirectClick = () => setRedirectDialogOpen(true);

  const onDeclineClick = () => setDeclineDialogOpen(true);

  const onApproveClick = () => setApproveDialogOpen(true);

  const onProceedDeclineClick = (comment: string) => {
    declineOffer(offerId, comment)
      .then(() => {
        MessageContext.message = {
          intent: "success",
          description: "Offer declined"
        };
        navigate("/tab");
      })
      .catch(err => {
        setDeclineDialogOpen(false);
        setErrorMessage(err);
      });
  };

  const onProceedApproveClick = (comment: string) => {
    approveOffer(offerId, comment)
      .then(() => {
        MessageContext.message = {
          intent: "success",
          description: "Offer approved"
        };
        navigate("/tab");
      })
      .catch(err => {
        setApproveDialogOpen(false);
        setErrorMessage(err);
      });
  };

  const onProceedRedirectClick = (assignedUser: string, comment: string) => {
    redirectOffer(offerId, {
      assignTo: assignedUser,
      comment: comment
    })
      .then(() => {
        MessageContext.message = {
          intent: "info",
          description: "Offer redirected"
        };
        navigate("/tab");
      })
      .catch(err => {
        setRedirectDialogOpen(false);
        setErrorMessage(err);
      });
  };

  const setErrorMessage = (error: Error) => {
    setMessage({
      intent: "error",
      title: "Error",
      description: error.message
    });
  };

  const formatDate = (date: string) => {
    return !date ? '' : new Date(date).toLocaleString('en-US', 
      { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'});
  }

  if (offer === null) {
    return (
      <Spinner
        size="large"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100%"
        }} 
      />
    )
  }

  if (offer.status !== OfferStatus.waitingForDecision && offer.status !== OfferStatus.redirected) {
    const showHistoryFeedButton = !offer.redirectedFrom;
    const goToHistoryFeed = () => {
      const date = new Date(offer.requestDate).toDateString();
      navigate(`/history-feed?offerId=${offerId}&tenantId=${offer.tenantId}&from=${date}&to=${date}&status=${offer.status}`);
    }
    return (
      <PageLayout message={message} onDismiss={onDismiss}>
        <Card className={styles.decisionMadeCard}>
          <img src={logo} height="67px" width="67px" alt="ITP logo"/>
            <Text style={{textAlign: "center"}}>
              Decision already made by {offer.decisionMakerName} ({offer.decisionMakerEmail}) on {formatDate(offer.decisionDate)}.<br/>
            </Text>
          <div className={styles.decisionMadeCardButtons}>
            <Button className={styles.button} appearance="secondary" onClick={() => navigate("/tab")}>
              Close
            </Button>
            {showHistoryFeedButton && <Button className={styles.button} appearance="primary" onClick={goToHistoryFeed}>
              History Feed
            </Button>}
          </div>
        </Card>
      </PageLayout>
    );
  }

  return (
    <PageLayout message={message} onDismiss={onDismiss}>
      <Card className={styles.card}>
        <div className={styles.header}>
          <img src={logo} height="67px" width="67px" alt="ITP logo"/>
          <Text className={styles.title} weight="bold">
            License Assistant. Offer based on employee request
          </Text>
          <div style={{width: "67px"}}></div>
        </div>
        <div className={styles.body}>
          <Text>
            You are seeing this form because a request to add functionality has been received from an employee of your company.
            We carefully analyzed the request and prepared an offer that should fully satisfy their needs.<br/>
            {offer.redirectedFrom && (<>
              <br/>
              This message was forwarded to you by {offer.redirectedFrom}.<br/>
              {offer.redirectComment && (<>Comment: {offer.redirectComment}.<br/></>)}
              <br/>
            </>)}
            <strong>Requestor name</strong>: {offer.initiatorName}({offer.initiatorEmail}).<br/>
            <strong>Request date and time</strong>: {formatDate(offer.requestDate)}<br/>
            <strong>Request:</strong><br/>
            <div style={{whiteSpace: 'pre-wrap'}}>
              {offer.requestText}
            </div>
          </Text>
          <Text>
            <strong>Offer from ITP:</strong><br/>
            <div style={{whiteSpace: 'pre-wrap'}}>
              {offer.offerText}
            </div>
          </Text>
          <Text>
            You may Approve, Decline, or Redirect the proposal to another authorized person of the company for a final decision.
            You can accompany each of your actions with a comment.<br/>
            When approving an offer with a comment, an ITP engineer will contact you to clarify the details.
          </Text>
        </div>
        <div className={styles.buttons}>
          <Button className={styles.button} onClick={() => navigate("/tab")}>
            Cancel
          </Button>
          <RedirectButton
            className={styles.button}
            onClick={onRedirectClick}
          />
          <DeclineButton
            className={styles.button}
            onClick={onDeclineClick}
          />
          <ApproveButton
            className={styles.button}
            onClick={onApproveClick}
          />
        </div>
      </Card>
      <RedirectDialog
        open={redirectDialogOpen}
        options={options}
        onCancelClick={() => setRedirectDialogOpen(false)}
        onProceedClick={onProceedRedirectClick}
      />
      <CommentDialog
        open={declineDialogOpen}
        onCancelClick={() => setDeclineDialogOpen(false)}
        onProceedClick={onProceedDeclineClick}
      />
      <CommentDialog
        open={approveDialogOpen}
        onCancelClick={() => setApproveDialogOpen(false)}
        onProceedClick={onProceedApproveClick}
      />
    </PageLayout>
  );
}