import { makeStyles, shorthands } from "@fluentui/react-components";
import { AppCard } from "./AppCard";
import { LicenseAssistantAppCard } from "./LicenseAssistantAppCard";
import toLogo from "../images/to_logo.png";
import tmLogo from "../images/tm_logo.png";
import laLogo from "../images/la_logo.png";
import lcLogo from "../images/lc_logo.png";

const useStyles = makeStyles({
  box: {
    ...shorthands.padding("0px", "10px", "10px")
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    columnGap: "10px",
    rowGap: "10px",
    maxWidth: "1080px",
    "@media (max-width: 720px)": {
      gridTemplateColumns: "auto"
    }
  }
})

export const Services = () => {
  const styles = useStyles();

  return (
    <div className={styles.box}>
      <h2>Our apps</h2>
      <div className={styles.gridContainer}>
        <AppCard title="Tenant Optimizer"
          description="Boost your tenant's efficiency: Register, Analyze, Optimize!"
          img = {toLogo}
          page="../tenant-optimizer" />
        <AppCard title="Tenant Pre-Migration Assistant"
          description="Explore your tenant-to-tenant migration project"
          img = {tmLogo}
          page="../migration-report" />
        <LicenseAssistantAppCard
          img = {laLogo} />
        <AppCard title="License Comparison"
          description="Explore, Compare, Empower: Uncover Microsoft Product Insights with Ease!"
          img = {lcLogo}
          page="../license-comparison" />
      </div>
    </div>
  );
}
