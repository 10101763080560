import {
	Combobox,
	ComboboxProps,
	Option,
	OptionOnSelectData,
	SelectionEvents
} from "@fluentui/react-components";
import { useState } from "react";
import { OfferStatus } from "../../models";

const options = [
	{ value: OfferStatus.submitted, text: "Submitted" },
	{ value: OfferStatus.waitingForDecision, text: "In Progress"},
	{ value: OfferStatus.approved, text: "Approved"},
	{ value: OfferStatus.declined, text: "Declined" },
	{ value: OfferStatus.redirected, text: "Redirected" },
];

export interface OfferStatusComboboxProps extends ComboboxProps {
	selectedOptions: string[]
	onOptionSelect: (event: SelectionEvents, data: OptionOnSelectData) => void
};

export const OfferStatusCombobox = (props: OfferStatusComboboxProps) => {
	const [showStatusText, setShowStatusText] = useState(true);

	const onFocus = () => {
    setShowStatusText(false);
  };

	const onBlur = () => {
    setShowStatusText(true);
  };

	const getStatusText = (selectedOptions: string[]) => {
		if (selectedOptions.length === options.length) {
			return "All selected";
		}
		if (selectedOptions.length > 1) {
			return `${selectedOptions.length} selected`;
		}
	
		return options.find(o => o.value === selectedOptions[0])?.text;
	}

	return (
		<Combobox
			multiselect={true}
			placeholder="Select a request status"
			value={showStatusText ? getStatusText(props.selectedOptions) : ""}
			onBlur={onBlur}
			onFocus={onFocus}
			selectedOptions={props.selectedOptions}
			onOptionSelect={props.onOptionSelect}
			style={{ minWidth: "200px" }}
			{...props}
		>
			{options.map((option) => (
				<Option key={option.value} value={option.value}>
					{option.text}
				</Option>
			))}
		</Combobox>
	);
};